import { styled, Box } from '@mui/material';

const StepperWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '36px 0',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    margin: '16px',
  },

  '& .cta-container': {
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
  },

  '& .secondaryBtn': {
    borderRadius: 24,
    fontFamily: 'Roboto-Medium',
    border: `2px solid ${theme.palette.primary.main}`,
    fontSize: 16,
    lineHeight: 1.38,
    padding: '7px 17px',
    textTransform: 'inherit',
    minWidth: 146,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
      border: `2px solid ${theme.palette.transparent.boxShadowDark01}`,
    },
  },

  '& .css-1890ug8-MuiTypography-root.Mui-selected:focus': {
    backgroundColor: 'pink', // theme.palette.primary.main,
  },

  '.wrapper': {
    background: theme.palette.background.paper,
    width: '658px',
    padding: 48,
    borderRadius: 4,
    boxShadow: `0 4px 20px 0 ${theme.palette.transparent.boxShadowDark01}`,
    border: `1px solid ${theme.palette.neutralLight.dark}`,
    '&--summary': {
      padding: '48px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '48px 16px',
      minWidth: '100%',
      width: 'auto',
      '&--summary': {
        padding: '48px 0',
      },
    },
    form: {
      width: '100%',
    },
    '&.fees-margin': {
      position: 'relative',
      marginRight: '350px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      [theme.breakpoints.down('md')]: {
        marginRight: '250px',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 'auto',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        padding: '24px 0',
        '.content, .main-form, .billing-form': {
          padding: '0 24px',
        },
        '.start-date-info': {
          position: 'relative',
          height: 'auto',
          right: '0',
          width: '100%',
          top: '0',
          margin: '16px 0',
          border: '0',
          borderTop: `1px solid ${theme.palette.primaryLight.dark}`,
          borderBottom: `1px solid ${theme.palette.primaryLight.dark}`,
          boxShadow: 'none',
          '&--summary': {
            backgroundColor: theme.palette.background.paper,
            padding: '24px 24px 0',
            borderBottom: '0',
          },
          '&--collapsed': {
            padding: '0 24px 24px',
            borderBottom: `1px solid ${theme.palette.primaryLight.dark}`,
            marginBottom: '16px',
          },
          '.start-date-info--title__summary': {
            padding: '0 36px 0 0',
          },
        },
      },
    },
    '.discount-warning': {
      padding: '4px 14px',
      '*': {
        margin: '0',
      },
      p: {
        color: '#895B00',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    '.content': {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      '&.first-page': {
        [theme.breakpoints.up('sm')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '30px 16px',
        },
      },
    },
    '.start-date-info': {
      position: 'absolute',
      height: 'calc(100% + 2px)',
      right: '-350px',
      width: '350px',
      top: '-1px',
      backgroundColor: '#F8FAFC',
      padding: '40px 24px',
      boxShadow: `10px 0px 10px 0px ${theme.palette.transparent.boxShadowDark01}`,
      border: `1px solid ${theme.palette.neutralLight.dark}`,
      borderLeftWidth: '0',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      margin: '0',
      [theme.breakpoints.down('md')]: {
        right: '-250px',
        width: '250px',
      },
      '&--title': {
        minHeight: 'auto',
        padding: '0',
        '> div': {
          margin: 'auto',
        },
        [theme.breakpoints.up('sm')]: {
          pointerEvents: 'none',
        },
      },
      '&--details': {
        padding: '0',
      },
      '.start-date-info--helper': {
        padding: '16px',
        borderRadius: '4px',
        background: '#E5F0FF',
      },
    },
  },

  '.stepWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '.headline': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
  },

  '.required-wrapper': {
    display: 'flex',
    padding: '16px',
    alignItems: 'flex-start',
    gap: '12px',
    color: '#475569',
    borderRadius: '4px',
    background: '#eff6ff',
    width: '100%',
    '> svg': {
      width: '20px',
      height: '20px',
      color: theme.palette.primary.main,
    },
    '> *': {
      textAlign: 'left',
      lineHeight: '20px',
    },
  },

  '.section': {
    display: 'inherit',
    flexDirection: 'column',
    width: '100%',
  },

  '.sectionBox': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: theme.palette.primaryLight.light,
    minHeight: '120px',
    width: '100%',
    textAlign: 'center',
    borderRadius: '4px',
    // padding: '32px 0',
    marginTop: 24,

    '&__redeem': {
      border: `1px solid ${theme.palette.error.main}`,
    },

    '&__voucher': {
      display: 'flex',
      gap: '6px',
      textAlign: 'left',
      backgroundColor: theme.palette.background.paper,
      fontWeight: 600,
      fontSize: '1em',
      margin: '4px auto',
      width: '86%',
      height: '68px',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '4px',
      padding: '0 24px',

      '&__value': {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        color: theme.palette.neutralDark.main,

        '& svg': {
          cursor: 'pointer',
        },

        '& .disabled': {
          opacity: '40%',
          pointerEvents: 'none',
        },
      },
    },

    '&__error': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },

    '&__costs': {
      margin: '0 auto',
      padding: '10px',
    },
    '&__costs-item': {
      display: 'flex',
      margin: '24px 16px',
      alignItems: 'center',
      flexWrap: 'wrap',
      columnGap: 60,
      justifyContent: 'space-between',
      marginBottom: '24px',

      '& .input': {
        display: 'flex',
        minWidth: '200px',
        height: '58px',
        padding: '16px',
        borderRadius: '4px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          maxWidth: '100%',
        },
      },

      '& .basic, .highlight': {
        backgroundColor: theme.palette.primaryLight.main,
      },

      '& .highlight': {
        color: theme.palette.primary.main,
        fontWeight: '600',
      },

      '& .error': {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
        display: 'flex',
        justifyContent: 'space-between',
      },

      '&__content': {
        width: '200px',
        textAlign: 'left',
        marginTop: '18px',
      },
    },

    '& .divider-basic, .divider-error': {
      borderTop: 'solid 1px',
      borderColor: theme.palette.neutralLight.dark,
    },

    '& .divider-highlight': {
      borderTop: 'solid 2px',
      borderColor: theme.palette.primary.main,
    },

    '& .highlighted': {
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  },

  '.title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
    textAlign: 'center',
    maxWidth: '524px',
    margin: '0 auto',
  },

  '.paymentTitle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '8px',
    },
    a: {
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-Medium',
      textDecoration: 'none',
    },
  },

  '.text': {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    textAlign: 'center',
    marginBottom: 43,
  },
  '.subtext': {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.main,
    textAlign: 'center',
    // marginBottom: 43,
  },
  legend: {
    fontSize: 13.5,
  },
  '.bold_checkbox .MuiFormControlLabel-label': {
    fontWeight: 600,
  },
  '.billing-form': { display: 'flex', flexDirection: 'column', gap: '30px' },
  '.subtitle': {
    fontWeight: 600,
  },
  '.steps': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '36px 0',
    gap: 14,
    position: 'relative',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
    },

    '&__back': {
      position: 'absolute',
      width: '85%',
      height: '2px',
      backgroundColor: theme.palette.primaryLight.main,
    },

    '&__item': {
      gap: 4,
      display: 'flex',
      alignItems: 'center',
      padding: '0 6px',
      borderRadius: 16,
      backgroundColor: theme.palette.primaryLight.main,
      fontSize: 13,
      lineHeight: 1.85,
      zIndex: 1,

      svg: {
        width: 16,
        height: 16,
        '+ b': {
          display: 'none',
          [theme.breakpoints.down('sm')]: {
            display: 'block',
          },
        },
      },

      '& .container-icon': {
        width: '12px',
        height: '12px',
        backgroundColor: 'white',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      span: {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '&_active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        span: {
          [theme.breakpoints.down('sm')]: {
            display: 'block',
            position: 'absolute',
            color: theme.palette.neutralDark.dark,
            fontFamily: "'Roboto-Bold'",
            fontSize: '18px',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, 40px)',
            whiteSpace: 'nowrap',
          },
        },
      },

      '&_error': {
        border: '2px solid',
        borderColor: theme.palette.error.main,
      },
    },
  },

  '.personalInfo': {
    gap: 30,
    display: 'flex',
    flexDirection: 'column',
  },

  '.billing-address': {
    gap: '30px 16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    '&.street-row': {
      gridTemplateColumns: '1fr 0.5fr',
    },
    '&.city-row': {
      gridTemplateColumns: '0.5fr 1fr',
    },
    '&.street-row, &.city-row': {
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
  },

  '.phone-content': {
    gap: '30px 16px',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },

  '.step-info': {
    width: '100%',
    marginBottom: '36px',
    padding: '0 48px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
    '&--title': {
      padding: '16px 0px',
      fontSize: 16,
      fontFamily: 'Roboto-Medium',
    },
  },

  '.raffle-wrapper': {
    padding: '24px',
    border: '1px solid #CBD5E1',
  },

  '.footer': {
    width: '100%',
    marginTop: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '36px',
      flexDirection: 'column-reverse',
      gap: 24,
      padding: '0px 24px',
      button: {
        width: '100%',
      },
    },

    '&.first': {
      justifyContent: 'flex-end',
      padding: '0px 24px',
    },

    '&.profit': {
      padding: '0px',
    },

    '&.step-info': {
      padding: '0 48px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 24px',
      },
    },
  },

  '& .container--inner': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '8px',
  },

  '.hint': {
    display: 'flex',
    gap: '8px',
  },

  '.paymentDetailsHolder': {
    marginTop: '16px',

    '& .textField': {
      letterSpacing: '0.04em',
      pointerEvents: 'none',
    },
    '& .editBtn': {
      cursor: 'pointer',
      pointerEvents: 'initial',
      padding: '6px 18px',
    },
  },
}));

export default StepperWrapper;
